#textp{
    overflow-wrap:  break-word !important;
}

#textbox{
    height: 20vh;
    overflow: scroll;
}

#container{
    height: 100vh !important;
}
.pdfCont{
    text-align: center !important;
}